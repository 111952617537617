import React, { useEffect, useMemo, useState } from 'react'
import { useFieldArray, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { LightButton, Select, sendNotification, TooltipButton } from 'components'
import { PlusIcon, DeleteBtnIcon } from 'icons'
import { useIntl } from 'react-intl'
import styles from './DynamicFormFields.module.scss'
import { Button, Row, Spinner } from 'react-bootstrap'
import { apiV1 } from 'utils'

export const DynamicFormFields = ({
  register,
  control,
  watch,
  name,
  dynamicFields,
  errors,
  readOnly,
  updateFunc,
  deleteFunc,
  setValue,
  selectOptions = [],
  organizationId,
  editFunc,
  getData,
}) => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(false)

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  })

  const watchFieldArray = watch(name)

  const handleSave = async fieldId => {
    try {
      setIsLoading(true)
      const field = controlledFields.find(el => el.id === fieldId)
      if (field) {
        typeof fieldId === 'string' ? await updateFunc({ ...field, ...organizationId }) : await editFunc({ ...field, ...organizationId })
        sendNotification(intl.formatMessage({ id: 'global.successfully' }), 'success')
      }
    } catch (err) {
      sendNotification(err.message, 'error')
    } finally {
      setIsLoading(false)
      getData()
    }
  }
  useEffect(() => {
    if (!fields.length) {
      append({})
    }
  }, [append, fields])

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    }
  })

  const getInfo = async (e, index) => {
    if (
      name === 'auditOrganizationjuridicalOwners' ||
      name === 'auditOrganizationOwners' ||
      name === 'auditOrganizationOtherOwners' ||
      name === 'auditAssociationPhysicalFounders' ||
      name === 'auditAssociationJuridicalFounders' ||
      name === 'auditAssociationOtherFounder'
    ) {
      if (organizationId?.ownerType) {
        let auditor
        switch (organizationId.ownerType) {
          case 1:
            auditor = await apiV1.get(`Auditor/GetById`, { params: { id: e?.target?.value } }).then(res => res.data)
            setValue(
              `${index}.information`,
              [auditor?.address, auditor?.irn, auditor?.dateOfIssueOfCertificate, auditor?.numberOfQualificationCertificate]
                .filter(auditInfo => auditInfo)
                .join(', '),
            )
            break
          case 2:
            auditor = await apiV1.get(`AuditOrganization/GetById`, { params: { id: e?.target?.value } }).then(res => res.data)
            setValue(
              `${index}.information`,
              [
                auditor?.address,
                auditor?.irn,
                auditor?.address,
                auditor?.inn,
                auditor?.dateOfIssueOfCertificate,
                auditor?.numberOfQualificationCertificate,
              ]
                .filter(auditInfo => auditInfo)
                .join(', '),
            )
            break
          case 3:
            console.log('case 3')
            break
        }
      }
    } else if (name === 'executiveOrganizationMembers' || name === 'employees') {
      const member = await apiV1.get(`Auditor/GetById`, { params: { id: e?.target?.value } }).then(res => res.data)
      setValue(
        `${index}.information`,
        [member?.address, member?.irn, member?.address, member?.inn, member?.dateOfIssueOfCertificate, member?.numberOfQualificationCertificate]
          .filter(auditInfo => auditInfo)
          .join(', '),
      )
    }
  }

  return (
    <div className={styles.container}>
      {controlledFields.map((controlledField, index) => {
        return (
          <div key={index} className={styles.inputs}>
            <Row className={styles.with_delete_btn} style={{ display: dynamicFields.length > 1 ? 'grid' : 'flex' }}>
              {dynamicFields?.map((el, idx) => {
                return el.type !== 'select' ? (
                  <div key={idx} className={styles.input_container}>
                    <label>{intl.formatMessage({ id: el?.translation })}</label>
                    {errors?.[`${name}.${index}.${el.name}`] && <span>{errors?.[`${name}.${index}.${el.name}`].message}</span>}
                    <input readOnly={readOnly || el?.readOnly} key={idx} type={el?.type} {...register(`${name}.${index}.${el.name}`)} />
                  </div>
                ) : (
                  <div key={idx} className={styles.input_container}>
                    <label>{intl.formatMessage({ id: el?.translation })}</label>
                    {errors?.[`${name}.${index}.${el.name}`] && <span>{errors?.[`${name}.${index}.${el.name}`].message}</span>}
                    <Controller
                      name={`${name}.${index}.${el.name}`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...register(`${name}.${index}.${el.name}`)}
                          key={idx}
                          disabled={readOnly}
                          name={`${name}.${index}.${el.name}`}
                          // initValue={controlledField?.ownerId || controlledField?.auditorId}
                          style={{ height: '100%' }}
                          list={selectOptions}
                          onChange={e => {
                            field.onChange(e)
                            getInfo(e, `${name}.${index}`)
                          }}
                          defaultMessage={intl.formatMessage({ id: 'global.all' })}
                        />
                      )}
                    />
                  </div>
                )
              })}

              {!readOnly && (
                <Row>
                  {typeof controlledField.id === 'string' ? (
                    <>
                      <Button variant="success" className="mr-3" onClick={() => handleSave(controlledField.id)}>
                        {isLoading ? <Spinner animation="border" size="sm" /> : intl.formatMessage({ id: 'global.create' })}
                      </Button>
                    </>
                  ) : (
                    <>
                      {name?.toLocaleLowerCase()?.includes('owners') && (
                        <Button variant="primary" className="mr-3" onClick={() => handleSave(controlledField.id)}>
                          {isLoading ? <Spinner animation="border" size="sm" /> : intl.formatMessage({ id: 'global.edit' })}
                        </Button>
                      )}
                      <Button
                        variant="danger"
                        onClick={async () => {
                          try {
                            name?.toLocaleLowerCase()?.includes('owners') || name?.toLocaleLowerCase()?.includes('founders')
                              ? await deleteFunc({ id: controlledField.id, ...organizationId })
                              : await deleteFunc(controlledField.id)
                            sendNotification(intl.formatMessage({ id: 'global.successfully' }), 'success')
                          } catch (err) {
                            sendNotification(err.message, 'error')
                          } finally {
                            await getData()
                          }
                        }}
                      >
                        {isLoading ? <Spinner animation="border" size="sm" /> : intl.formatMessage({ id: 'global.delete' })}
                      </Button>
                    </>
                  )}
                </Row>
              )}

              {!readOnly && (
                <>
                  {index !== 0 && (
                    <TooltipButton
                      className={styles.delete_btn}
                      ButtonIcon={DeleteBtnIcon}
                      onClick={async () => {
                        try {
                          name?.toLocaleLowerCase()?.includes('owners') || name?.toLocaleLowerCase()?.includes('founders')
                            ? await deleteFunc({ id: controlledField.id, ...organizationId })
                            : await deleteFunc(controlledField.id)
                          sendNotification(intl.formatMessage({ id: 'global.successfully' }), 'success')
                        } catch (err) {
                          sendNotification(err.message, 'error')
                        } finally {
                          remove(index)
                          await getData()
                        }
                      }}
                      tooltipText={intl.formatMessage({ id: 'global.delete' })}
                    />
                  )}
                </>
              )}
            </Row>

            {!readOnly && (
              <>
                {watchFieldArray.length - 1 === index && (
                  <LightButton
                    ButtonIcon={PlusIcon}
                    btnText={intl.formatMessage({ id: 'add.btn' })}
                    onClick={() => {
                      append({})
                    }}
                    className={styles.btn}
                  />
                )}
              </>
            )}
          </div>
        )
      })}
    </div>
  )
}

DynamicFormFields.propTypes = {
  register: PropTypes.any,
  control: PropTypes.any,
  reset: PropTypes.any,
  watch: PropTypes.any,
  name: PropTypes.string,
  errors: PropTypes.any,
  dynamicFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  readOnly: PropTypes.bool,
  updateFunc: PropTypes.any,
  setValue: PropTypes.func,
  deleteFunc: PropTypes.any,
  editFunc: PropTypes.any,
  organizationId: PropTypes.object,
  selectOptions: PropTypes.array,
  getData: PropTypes.func,
}
