import { types } from 'mobx-state-tree'

export const AuditorModel = types.model('AuditorModel', {
  id: types.number,
  fullNameAuditor: types.string,
  numberOfQualificationCertificate: types.maybeNull(types.string),
  dateOfIssueCertificate: types.maybeNull(types.string),
  auditorsPosition: types.maybeNull(types.string),
  organizationAttribute: types.maybeNull(types.boolean),
})

export default AuditorModel
