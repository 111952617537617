import PropTypes from 'prop-types'
import React from 'react'
import './Select.scss'

// eslint-disable-next-line react/display-name
export const Select = React.forwardRef(({ onChange, list, defaultMessage, initValue, ...props }, ref) => {
  const handleSelectChange = event => {
    if (event.target.value != -1) {
      onChange(event)
    } else {
      onChange(null)
    }
  }
  return (
    <select defaultValue={initValue ? initValue : -1} className="custom_select" onChange={handleSelectChange} ref={ref} {...props}>
      {defaultMessage && <option value={-1}>{defaultMessage}</option>}
      {list?.map(el => (
        <option key={el.id} value={el.id}>
          {el.name}
        </option>
      ))}
    </select>
  )
})
Select.propTypes = {
  onChange: PropTypes.func,
  list: PropTypes.array,
  defaultMessage: PropTypes.string,
  initValue: PropTypes.any,
}
