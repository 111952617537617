import AdminsStore from './AdminsStore'
import AppStore from './AppStore'
import AuthStore from './AuthStore'
import BanksStore from './BanksStore'
import ReportsStore from './ReportsStore'
import CommonStore from './CommonStore'
import ReferenceBooksStore from './ReferenceBooksStore'
import UsersStore from './UsersStore'
import AuditorsStore from './AuditorsStore'
import AuditorOrganizationStore from './AuditorOrganizationStore'
import AuditorIndividualStore from './AuditorIndividuals'
import AuditorAssociationStore from './AuditorAssociationStore'

const rootStore = {
  appStore: AppStore.create(),
  authStore: AuthStore.create(),
  adminsStore: AdminsStore.create(),
  banksStore: BanksStore.create(),
  reportsStore: ReportsStore.create(),
  commonStore: CommonStore.create(),
  referenceBooksStore: ReferenceBooksStore.create(),
  usersStore: UsersStore.create(),
  auditorsStore: AuditorsStore.create(),
  auditorOrganizationStore: AuditorOrganizationStore.create(),
  auditorIndividualStore: AuditorIndividualStore.create(),
  auditorAssociationStore: AuditorAssociationStore.create(),
}

export { rootStore as stores }
