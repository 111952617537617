import { applySnapshot, getSnapshot, types } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { AuditorAssociationModel, ParticipantModel, AuditorOrganizationLookUpModel, ProfessionalAuditAssociationLookUps } from 'stores/models'
import { apiV1 } from '../../utils'
import { when } from 'mobx'

const AuditorAssociationStore = types
  .model('AuditorAssociationStore')
  .props({
    _associations: types.optional(types.array(AuditorAssociationModel), []),
    _participants: types.optional(types.array(ParticipantModel), []),
    _auditOrganizations: types.optional(types.array(AuditorOrganizationLookUpModel), []),
    _deletedAssociations: types.optional(types.array(AuditorAssociationModel), []),
    _auditAssociations: types.optional(types.array(ProfessionalAuditAssociationLookUps), []),
    _isPending: types.optional(types.boolean, false),
  })
  .actions(self => {
    const API_URL = '/ProfessionalAuditAssociation'

    const setAssociations = data => {
      applySnapshot(self._associations, data)
    }
    const setParticipants = data => {
      applySnapshot(self._participants, data)
    }
    const setAuditOrganizations = data => {
      applySnapshot(self._auditOrganizations, data)
    }
    const setDeletedAssociations = data => {
      applySnapshot(self._deletedAssociations, data)
    }
    const setAuditAssociations = data => {
      applySnapshot(self._auditAssociations, data)
    }

    const getAssociations = () => {
      const request = fromPromise(apiV1.get(`${API_URL}/AuditAssociations`))
      when(() => {
        request.case({
          fulfilled: response => {
            setAssociations(response.data)
            return true
          },
        })
      })
      return request
    }
    const getParticipants = id => {
      const request = fromPromise(apiV1.get(`${API_URL}/GetById?id=${id}`, { id }))
      when(() => {
        request.case({
          fulfilled: response => {
            setParticipants(response.data.participants)
            return true
          },
        })
      })
      return request
    }
    const getAuditOrganizations = id => {
      let request
      if (id) request = fromPromise(apiV1.get(`${API_URL}/AuditOrganizations?id=${id}&organizationAttribute=true`, { id }))
      else request = fromPromise(apiV1.get(`${API_URL}/AuditOrganizations?organizationAttribute=true`))
      when(() => {
        request.case({
          fulfilled: response => {
            setAuditOrganizations(response.data)
            return true
          },
        })
      })
      return request
    }
    const getArchive = () => {
      const request = fromPromise(apiV1.get(`${API_URL}/DeletionAuditAssociations`))
      when(() => {
        request.case({
          fulfilled: response => {
            setDeletedAssociations(response.data)
            return true
          },
        })
      })
      return request
    }
    const recoverAssociation = id => {
      const request = fromPromise(apiV1.put(`${API_URL}/CancelDeletionAuditAssociation?id=${id}`))
      when(() => {
        request.case({
          fulfilled: response => {
            getArchive()
            return true
          },
        })
      })
      return request
    }
    const deleteAssociation = id => {
      const request = fromPromise(apiV1.delete(`${API_URL}/DeleteAuditAssociation?id=${id}`))
      when(() => {
        request.case({
          fulfilled: response => {
            getAssociations()
            return true
          },
        })
      })
      return request
    }
    const deleteParticipant = data => {
      const request = fromPromise(apiV1.delete(`${API_URL}/DeleteParticipant`, { data }))
      return request
    }

    const editAssociation = async data => {
      const request = fromPromise(apiV1.put(`${API_URL}/EditAuditAssociation`, data))
      return request
    }

    const addNewAssociation = async data => {
      const request = fromPromise(apiV1.post(`${API_URL}/CerateAuditAssociation`, data))
      return request
    }

    const addParticipant = async data => {
      if (data.associationId) data.id = data.associationId
      const request = fromPromise(apiV1.post(`${API_URL}/AddParticipant`, data))
      return request
    }

    const exportCSV = () => {
      const request = fromPromise(apiV1.get(`CsvExport/GetCsvFileOfProfessionalAuditAssociations`))
      return request
    }

    const updatePhysicalAddress = data => {
      const request = fromPromise(apiV1.post(`${API_URL}/AddPhysicalAddress`, data))
      return request
    }
    const archivePhysicalAddress = data => {
      const request = fromPromise(apiV1.put(`${API_URL}/InArchivePhysicalAddress`, data))
      return request
    }

    const getAuditAssociations = () => {
      const request = fromPromise(apiV1.get(`${API_URL}/ProfessionalAuditAssociationLookUps`))
      when(() => {
        request.case({
          fulfilled: response => {
            let changedArr = []
            for (const el of response.data) {
              changedArr.push({
                id: el?.id,
                name: el?.shortTitle,
              })
            }
            setAuditAssociations(changedArr)
            return true
          },
        })
      })
      return request
    }
    const addFounder = data => {
      data.id = 0
      data.shareInTheAuthorizedCapital = parseInt(data.shareInTheAuthorizedCapital, 10)
      const request = fromPromise(apiV1.post(`${API_URL}/AddFounder`, data))
      return request
    }
    const deleteFounder = data => {
      const request = fromPromise(apiV1.delete(`${API_URL}/DeleteFounder`, { data }))
      when(() => {
        request.case({
          fulfilled: response => {
            // getOrganizations()
            return true
          },
        })
      })
      return request
    }
    return {
      getAssociations,
      setAssociations,
      editAssociation,
      addNewAssociation,
      addParticipant,
      setParticipants,
      getParticipants,
      getArchive,
      recoverAssociation,
      deleteAssociation,
      deleteParticipant,
      exportCSV,
      updatePhysicalAddress,
      archivePhysicalAddress,
      getAuditOrganizations,
      getAuditAssociations,
      addFounder,
      deleteFounder,
    }
  })
  .views(self => ({
    get associations() {
      return getSnapshot(self._associations)
    },
    get participants() {
      return getSnapshot(self._participants)
    },
    get deletedAssociations() {
      return getSnapshot(self._deletedAssociations)
    },
    get isPending() {
      return self._isPending
    },
    get auditOrganizations() {
      return self._auditOrganizations
    },
    get auditAssociations() {
      return self._auditAssociations
    },
  }))

export default AuditorAssociationStore
