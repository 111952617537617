import PropTypes from 'prop-types'
import { useStore } from 'hooks'

export const ShowIfAuth = ({ allowedRoles, children }) => {
  const authStore = useStore('authStore')

  return allowedRoles?.includes(authStore?.user?.userRole) ? children : null
}

ShowIfAuth.propTypes = {
  allowedRoles: PropTypes.array,
  children: PropTypes.any,
}
