import { types } from 'mobx-state-tree'

export const AuditorAssociationModel = types.model('AuditorAssociationModel', {
  id: types.number,
  shortTitle: types.string,
  phoneNumberOrganization: types.string,
  emailAddressOrganization: types.string,
  certificateNumber: types.maybeNull(types.string),
  dateOfIssueOfCertificate: types.maybeNull(types.string),
  organizationAttribute: types.boolean,
})

export const ParticipantModel = types.model('ParticipantModel', {
  id: types.number,
  name: types.string,
  address: types.string,
  typeOfRegisteredObject: types.number,
})

export const AuditorOrganizationLookUpModel = types.model('AuditorOrganizationLookUpModel', {
  id: types.number,
  name: types.string,
})

export const ProfessionalAuditAssociationLookUps = types.model('ProfessionalAuditAssociationLookUps', {
  id: types.number,
  name: types.string,
})

export default AuditorAssociationModel
