import { types } from 'mobx-state-tree'

export const AuditorOrganizationModel = types.model('AuditorOrganizationModel', {
  id: types.number,
  shortTitle: types.string,
  phoneNumberOrganization: types.string,
  emailAddressOrganization: types.string,
  // registrationCertificateNumber: types.string,
  // dateOfIssueOfRegistrationCertificate: types.string,
  organizationAttribute: types.boolean,
})

export default AuditorOrganizationModel
