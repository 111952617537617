import { types } from 'mobx-state-tree'

export const AuditorIndividualModel = types.model('AuditorIndividualModel', {
  id: types.number,
  fullNameAuditor: types.string,
  numberOfCertificateOfAnIndividualEntrepreneur: types.string,
  dateOfIssueOfCertificateOfAnIndividualEntrepreneur: types.string,
  organizationAttribute: types.boolean,
})

export default AuditorIndividualModel
