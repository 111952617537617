export const ROLES = Object.freeze({
  administrator: 'Administrator',
  operator: 'Operator',
  registrator: 'Registrar',
})

export const REGISTRIES = Object.freeze({
  1: 'auditor',
  2: 'auditor_entrepreneur',
  3: 'auditor_organization',
})

export const LANGUAGES = Object.freeze({
  en: 'en-US',
  ru: 'ru-RU',
  kg: 'ky-KG',
})

export const VALIDATION_REGEX = Object.freeze({
  EMAIL:
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
  NO_ONLY_SPACES: /.*[^ ].*/,
  NO_SPACES: /^[a-zA-Z0-9]*$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
})
