import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { ArrowDown, ArrowUp } from 'icons'
import { Spinner, Button, Row } from 'react-bootstrap'
import styles from './ArchivedFormFields.module.scss'
import { sendNotification, Select } from 'components'

export const ArchivedFormFields = ({
  fields = [],
  fieldsEmptyState = [],
  updateActiveField,
  archiveActiveField,
  ownerId,
  selectOptions = [],
  register,
}) => {
  const intl = useIntl()

  const [activeField, setActiveField] = useState({})
  const [archivedFields, setArchivedFields] = useState(fields.filter(el => !el?.isArchive))
  const [isArchiveOpen, setIsArchiveOpen] = useState(false)
  const [isChangeable, setIsChangeable] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (fields.length > 0) {
      setActiveField(fields.filter(el => !el?.isArchive)[0])
      setArchivedFields(fields.filter(el => el?.isArchive))
    }
  }, [fields])

  const toggleArchivedFields = e => {
    e.preventDefault()
    setIsArchiveOpen(!isArchiveOpen)
  }

  const handleFieldChange = e => {
    setActiveField({ ...activeField, [e.target.name]: e.target.value })
  }

  const handleArchiveField = async e => {
    try {
      // 1. Clear active field
      setIsChangeable(true)

      // 2. Add field to archivedFields
      await archiveActiveField({ ...activeField, ...ownerId, isArchive: true })
      await setArchivedFields([...archivedFields, activeField])
      await setActiveField({ ...activeField, id: 0 })

      sendNotification(intl.formatMessage({ id: 'global.successfully' }), 'success')
    } catch (err) {
      sendNotification(err.message, 'error')
    }
  }

  const handleCancel = () => {
    setIsChangeable(false)
  }

  const handleSave = async () => {
    try {
      setIsLoading(true)
      const newData = await updateActiveField({ ...activeField, ...ownerId }).then(res => res.data)
      await setActiveField(newData)
      sendNotification(intl.formatMessage({ id: 'global.successfully' }), 'success')
    } catch (err) {
      sendNotification(err.message, 'error')
    } finally {
      setIsArchiveOpen(false)
      setIsChangeable(false)
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.active_fields}>
        {fieldsEmptyState.map((field, idx) =>
          field.type !== 'select' ? (
            <div key={idx}>
              <label htmlFor={field.name}>{intl.formatMessage({ id: field.translation })}</label>
              <input name={field.name} disabled={!isChangeable} type={field.type} value={activeField?.[field.name]} onChange={handleFieldChange} />
            </div>
          ) : (
            <div key={idx}>
              <label htmlFor={field.name}>{intl.formatMessage({ id: field.translation })}</label>
              <Select
                {...register(field.name)}
                key={idx}
                name={field.name}
                // initValue={activeField?.[field.name]}
                value={activeField?.[field.name]}
                disabled={!isChangeable}
                style={{ height: '100%' }}
                list={selectOptions}
                onChange={handleFieldChange}
                defaultMessage={intl.formatMessage({ id: 'global.all' })}
              />
            </div>
          ),
        )}
      </div>

      <Row className={styles.action_bar}>
        <Row>
          {isChangeable ? (
            <>
              <Button variant="light" onClick={handleCancel} style={{ marginRight: '1rem' }}>
                {intl.formatMessage({ id: 'global.cancel' })}
              </Button>
              <Button variant="success" onClick={handleSave}>
                {isLoading ? <Spinner animation="border" size="sm" /> : 'Save'}
              </Button>
            </>
          ) : (
            <>
              {activeField && !activeField.isArchive && (
                <Button variant="outline-secondary" onClick={handleArchiveField} style={{ marginRight: '1rem' }}>
                  {intl.formatMessage({ id: 'to_archive' })}
                </Button>
              )}
              <Button onClick={() => setIsChangeable(true)}>{intl.formatMessage({ id: 'global.edit' })}</Button>
            </>
          )}
        </Row>

        <Row className={styles.archive_opener}>
          {intl.formatMessage({ id: 'archived_fields' })}
          <Button variant="light" onClick={toggleArchivedFields} style={{ position: 'relative', marginLeft: '1rem' }}>
            <span className={styles.archive_cnt}>{archivedFields.length}</span>
            {isArchiveOpen ? <ArrowUp /> : <ArrowDown />}
          </Button>
        </Row>
      </Row>

      {isArchiveOpen && (
        <>
          {!archivedFields.length ? (
            <Row className={`${styles.empty_mode} archived_fields`}>
              <h3>{intl.formatMessage({ id: 'global.empty' })}</h3>
            </Row>
          ) : (
            <>
              {archivedFields.map((el, idx) => {
                return (
                  <div key={idx} className="archived_fields">
                    {fieldsEmptyState.map(field =>
                      field.type !== 'select' ? (
                        <div key={el.id}>
                          <label htmlFor={field.name}>{intl.formatMessage({ id: field.translation })}</label>
                          <input readOnly name={field.name} value={archivedFields?.[idx]?.[field.name]} />
                        </div>
                      ) : (
                        // <></>
                        <div key={el.id}>
                          <label htmlFor={field.name}>{intl.formatMessage({ id: field.translation })}</label>
                          <input
                            readOnly
                            name={field.name}
                            value={selectOptions?.filter(el => el.id === archivedFields?.[idx]?.[field.name])[0]?.name}
                          />
                        </div>
                      ),
                    )}
                  </div>
                )
              })}
            </>
          )}
        </>
      )}
    </div>
  )
}

ArchivedFormFields.propTypes = {
  name: PropTypes.string,
  fields: PropTypes.array,
  fieldsEmptyState: PropTypes.array,
  updateActiveField: PropTypes.func,
  archiveActiveField: PropTypes.func,
  ownerId: PropTypes.object,
  selectOptions: PropTypes.array,
  register: PropTypes.any,
}
