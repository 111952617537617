import { applySnapshot, getSnapshot, types } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { AuditorOrganizationModel } from 'stores/models'
import { apiV1 } from '../../utils'
import { when } from 'mobx'

const AuditorOrganizationStore = types
  .model('AuditorOrganizationStore')
  .props({
    _organizations: types.optional(types.array(AuditorOrganizationModel), []),
    _organizationIndividuals: types.optional(types.array(AuditorOrganizationModel), []),
    _deletedOrganizations: types.optional(types.array(AuditorOrganizationModel), []),
    _isPending: types.optional(types.boolean, false),
  })
  .actions(self => {
    const API_URL = '/AuditOrganization'

    const setOrganizations = data => {
      applySnapshot(self._organizations, data)
    }
    const setOrganizationsIndividual = data => {
      applySnapshot(self._organizationIndividuals, data)
    }
    const setDeletedOrganizations = data => {
      applySnapshot(self._deletedOrganizations, data)
    }

    const getOrganizations = () => {
      const request = fromPromise(apiV1.get(`${API_URL}/AuditOrganizations?organizationAttribute=false`))
      when(() => {
        request.case({
          fulfilled: response => {
            setOrganizations(response.data)
            return true
          },
        })
      })
      return request
    }

    const getOrganizationsIndividual = () => {
      const request = fromPromise(apiV1.get(`${API_URL}/AuditOrganizations?organizationAttribute=true`))
      when(() => {
        request.case({
          fulfilled: response => {
            setOrganizationsIndividual(response.data)
            return true
          },
        })
      })
      return request
    }

    const getArchive = () => {
      const request = fromPromise(apiV1.get(`${API_URL}/DeletionAuditOrganizations`))
      when(() => {
        request.case({
          fulfilled: response => {
            setDeletedOrganizations(response.data)
            return true
          },
        })
      })
      return request
    }
    const recoverOrganization = id => {
      const request = fromPromise(apiV1.put(`${API_URL}/CancelDeletionAuditOrganization?id=${id}`))
      when(() => {
        request.case({
          fulfilled: response => {
            getArchive()
            return true
          },
        })
      })
      return request
    }
    const deleteOrganization = id => {
      const request = fromPromise(apiV1.delete(`${API_URL}/DeleteAuditOrganization?id=${id}`))
      when(() => {
        request.case({
          fulfilled: response => {
            getOrganizations()
            getOrganizationsIndividual()
            return true
          },
        })
      })
      return request
    }

    const editOrganization = async data => {
      const request = fromPromise(apiV1.put(`${API_URL}/EditAuditOrganization`, data))
      return request
    }

    const addNewOrganization = async data => {
      const request = fromPromise(apiV1.post(`${API_URL}/CreateAuditOrganization`, data))
      return request
    }

    const exportCSV = () => {
      const request = fromPromise(apiV1.get(`CsvExport/GetCsvFileOfAuditOrganizations`))
      return request
    }

    const updatePhysicalAddress = data => {
      const request = fromPromise(apiV1.post(`${API_URL}/AddPhysicalAddress`, data))
      return request
    }
    const archivePhysicalAddress = data => {
      const request = fromPromise(apiV1.put(`${API_URL}/InArchivePhysicalAddress`, data))
      return request
    }
    const updateOrganizationReceipt = data => {
      const request = fromPromise(apiV1.post(`${API_URL}/AddOrEditReceipt`, data))
      return request
    }
    const archiveOrganizationReceipt = data => {
      const request = fromPromise(apiV1.put(`${API_URL}/InArchiveReceipt`, data))
      return request
    }
    const updateLicenseStatus = data => {
      const request = fromPromise(apiV1.post(`${API_URL}/AddLicenseStatus`, data))
      return request
    }
    const archiveLicenseStatus = data => {
      const request = fromPromise(apiV1.put(`${API_URL}/InArchiveLicenseStatus`, data))
      return request
    }
    const updateDuplicateLicense = data => {
      const request = fromPromise(apiV1.post(`${API_URL}/AddDuplicateLicense`, data))
      return request
    }
    const archiveDuplicateLicense = data => {
      const request = fromPromise(apiV1.put(`${API_URL}/InArchiveDuplicateLicense`, data))
      return request
    }
    const addOrganizationOwner = data => {
      data.id = 0
      data.shareInTheAuthorizedCapital = parseInt(data.shareInTheAuthorizedCapital, 10)
      const request = fromPromise(apiV1.post(`${API_URL}/AddOrganizationOwner`, data))
      return request
    }
    const updateOrganizationOwner = data => {
      data.shareInTheAuthorizedCapital = parseInt(data.shareInTheAuthorizedCapital, 10)
      // data = {
      //   id: data?.id,
      //   auditOrganizationId: data?.auditOrganizationId,
      //   ownerId: data?.ownerId,
      //   shareInTheAuthorizedCapital: data?.shareInTheAuthorizedCapital,
      //   ownerType: data?.ownerType,
      //   information: data?.information
      // }
      const request = fromPromise(apiV1.put(`${API_URL}/EditOrganizationOwner`, data))
      return request
    }

    const deleteOrganizationOwner = data => {
      const request = fromPromise(apiV1.delete(`${API_URL}/DeleteOwner`, { data }))
      when(() => {
        request.case({
          fulfilled: response => {
            getOrganizations()
            return true
          },
        })
      })
      return request
    }

    const addExecutiveOrganizationMember = data => {
      const request = fromPromise(apiV1.post(`${API_URL}/AddExecutiveOrganizationMember`, data))
      return request
    }
    const deleteExecutiveOrganizationMember = id => {
      const request = fromPromise(apiV1.delete(`${API_URL}/DeleteExecutiveOrganizationMember?executiveOrganizationMemberId=${id}`))
      when(() => {
        request.case({
          fulfilled: response => {
            // getOrganizations()
            return true
          },
        })
      })
      return request
    }
    const addEmployee = data => {
      data.id = 0
      const request = fromPromise(apiV1.post(`${API_URL}/AddEmployee`, { ...data, isArchive: false }))
      return request
    }
    const deleteEmployee = id => {
      const request = fromPromise(apiV1.delete(`${API_URL}/DeleteEmployee?placeworkAuditorId=${id}`))
      when(() => {
        request.case({
          fulfilled: response => {
            // getOrganizations()
            return true
          },
        })
      })
      return request
    }
    // const deleteOrganizationOwner = data => {
    //   console.log("data", data)
    //   const request = fromPromise(apiV1.delete(`${API_URL}/DeleteOwner`, data))
    //   return request
    // }

    return {
      getOrganizations,
      setOrganizations,
      setOrganizationsIndividual,
      editOrganization,
      addNewOrganization,
      getArchive,
      recoverOrganization,
      deleteOrganization,
      exportCSV,
      updatePhysicalAddress,
      archivePhysicalAddress,
      updateLicenseStatus,
      archiveLicenseStatus,
      updateDuplicateLicense,
      archiveDuplicateLicense,
      updateOrganizationReceipt,
      archiveOrganizationReceipt,
      addOrganizationOwner,
      deleteOrganizationOwner,
      getOrganizationsIndividual,
      updateOrganizationOwner,
      addExecutiveOrganizationMember,
      deleteExecutiveOrganizationMember,
      addEmployee,
      deleteEmployee,
    }
  })
  .views(self => ({
    get organizations() {
      return getSnapshot(self._organizations)
    },
    get organizationsIndividual() {
      return getSnapshot(self._organizationIndividuals)
    },
    get deletedOrganizations() {
      return getSnapshot(self._deletedOrganizations)
    },
    get isPending() {
      return self._isPending
    },
  }))

export default AuditorOrganizationStore
