import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { DynamicFormFields, ArchivedFormFields } from 'components'

export const FormGenerator = ({ formFields, register, errors, control, watch, setValue }) => {
  const intl = useIntl()

  return (
    <>
      {formFields.map((field, idx) => {
        switch (field.type) {
          case 'dynamic':
            return (
              <>
                {field?.label && <label>{intl.formatMessage({ id: field.label })}</label>}
                <DynamicFormFields
                  key={idx}
                  register={register}
                  control={control}
                  watch={watch}
                  name={field.name}
                  dynamicFields={field.dynamicFields}
                  errors={errors}
                  readOnly={field.readOnly}
                  setValue={setValue}
                  updateFunc={field.updateFunc}
                  deleteFunc={field.deleteFunc}
                  organizationId={field.associationId}
                  selectOptions={field.selectOptions || []}
                  getData={field.getData}
                />
              </>
            )
          case 'archived':
            return <ArchivedFormFields key={idx} {...field} />
          default:
            return (
              <div key={idx} className={field.className} style={field.style}>
                <label htmlFor={field.name}>
                  <p>{intl.formatMessage({ id: field.id })}</p>
                  {field?.label && (
                    <p style={{ fontSize: '0.8rem', color: '#0d1013', fontStyle: 'italic' }} className="mt-1">
                      {intl.formatMessage({ id: field.label })}
                    </p>
                  )}
                </label>
                {errors[field.name] && <span> {errors[field.name].message}</span>}
                <input name={field.name} type={field.type} {...register(field.name)} readOnly={field.readOnly} checked={field.checked} />
              </div>
            )
        }
      })}
    </>
  )
}
FormGenerator.propTypes = {
  formFields: PropTypes.array,
  register: PropTypes.any,
  errors: PropTypes.any,
  control: PropTypes.any,
  watch: PropTypes.any,
  setValue: PropTypes.any,
}
