import * as yup from 'yup'

export const formValidationGenerator = formFields => {
  const fields = (() => {
    return formFields.reduce((acc, field) => ((acc[field.name] = field.validation), acc), {})
  })()

  const schema = yup.object().shape(fields)

  return schema
}
