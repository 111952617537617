import { applySnapshot, getSnapshot, types } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { AuditorIndividualModel } from 'stores/models'
import { apiV1 } from '../../utils'
import { when } from 'mobx'

const AuditorIndividualsStore = types
  .model('AuditorIndividualsStore')
  .props({
    _individuals: types.optional(types.array(AuditorIndividualModel), []),
    _deletedIndividuals: types.optional(types.array(AuditorIndividualModel), []),
    _isPending: types.optional(types.boolean, false),
  })
  .actions(self => {
    const API_URL = '/AuditorIndividualEntrepreneur'

    const setIndividuals = data => {
      applySnapshot(self._individuals, data)
    }
    const setDeletedIndividuals = data => {
      applySnapshot(self._deletedIndividuals, data)
    }

    const getIndividuals = () => {
      const request = fromPromise(apiV1.get(`${API_URL}/AuditorIndividuals`))
      when(() => {
        request.case({
          fulfilled: response => {
            setIndividuals(response.data)
            return true
          },
        })
      })
      return request
    }
    const getArchive = () => {
      const request = fromPromise(apiV1.get(`${API_URL}/DeletionAuditorIndividuals`))
      when(() => {
        request.case({
          fulfilled: response => {
            setDeletedIndividuals(response.data)
            return true
          },
        })
      })
      return request
    }
    const recoverIndividual = id => {
      const request = fromPromise(apiV1.put(`${API_URL}/CancelDeletionAuditorIndividual?id=${id}`))
      when(() => {
        request.case({
          fulfilled: response => {
            getArchive()
            return true
          },
        })
      })
      return request
    }
    const deleteIndividual = id => {
      const request = fromPromise(apiV1.delete(`${API_URL}/DeleteAuditorIndividual?id=${id}`))
      when(() => {
        request.case({
          fulfilled: response => {
            getIndividuals()
            return true
          },
        })
      })
      return request
    }

    const editIndividual = async data => {
      const request = fromPromise(apiV1.put(`${API_URL}/EditAuditorIndividual`, data))
      return request
    }

    const addNewIndividual = async data => {
      const request = fromPromise(apiV1.post(`${API_URL}/CerateAuditorIndividual`, data))
      return request
    }

    const exportCSV = () => {
      const request = fromPromise(apiV1.get(`CsvExport/GetCsvFileOfAuditorIndividualEntrepreneurs`))
      return request
    }

    const updateResidentialAddress = data => {
      const request = fromPromise(apiV1.post(`${API_URL}/AddResidentialAddress`, data))
      return request
    }
    const archiveResidentialAddress = data => {
      const request = fromPromise(apiV1.put(`${API_URL}/InArchiveResidentialAddress`, data))
      return request
    }

    const updateLicenseStatus = data => {
      const request = fromPromise(apiV1.post(`${API_URL}/AddLicenseStatus`, data))
      return request
    }
    const archiveLicenseStatus = data => {
      const request = fromPromise(apiV1.put(`${API_URL}/InArchiveLicenseStatus`, data))
      return request
    }

    const updateDuplicateLicense = data => {
      const request = fromPromise(apiV1.post(`${API_URL}/AddDuplicateLicense`, data))
      return request
    }
    const archiveDuplicateLicense = data => {
      const request = fromPromise(apiV1.put(`${API_URL}/InArchiveDuplicateLicense`, data))
      return request
    }

    return {
      getIndividuals,
      setIndividuals,
      editIndividual,
      addNewIndividual,
      recoverIndividual,
      getArchive,
      deleteIndividual,
      exportCSV,
      updateResidentialAddress,
      archiveResidentialAddress,
      updateLicenseStatus,
      archiveLicenseStatus,
      updateDuplicateLicense,
      archiveDuplicateLicense,
    }
  })
  .views(self => ({
    get individuals() {
      return getSnapshot(self._individuals)
    },
    get deletedIndividuals() {
      return getSnapshot(self._deletedIndividuals)
    },
    get isPending() {
      return self._isPending
    },
  }))

export default AuditorIndividualsStore
