import { applySnapshot, getSnapshot, types } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { AuditorModel } from 'stores/models'
import { apiV1 } from '../../utils'
import { when } from 'mobx'

const AuditorsStore = types
  .model('AuditorsStore')
  .props({
    _auditors: types.optional(types.array(AuditorModel), []),
    _deletedAuditors: types.optional(types.array(AuditorModel), []),
    _isPending: types.optional(types.boolean, false),
  })
  .actions(self => {
    const API_URL = '/Auditor'

    const setAuditors = data => {
      applySnapshot(self._auditors, data)
    }
    const setDeletedAuditors = data => {
      applySnapshot(self._deletedAuditors, data)
    }

    const getAuditors = () => {
      const request = fromPromise(apiV1.get(`${API_URL}/Auditors`))
      when(() => {
        request.case({
          fulfilled: response => {
            setAuditors(response.data)
            return true
          },
        })
      })
      return request
    }

    const getArchive = () => {
      const request = fromPromise(apiV1.get(`${API_URL}/DeletionAuditors`))
      when(() => {
        request.case({
          fulfilled: response => {
            setDeletedAuditors(response.data)
            return true
          },
        })
      })
      return request
    }
    const recoverAuditor = id => {
      const request = fromPromise(apiV1.put(`${API_URL}/CancelDeletionAuditor?id=${id}`))
      when(() => {
        request.case({
          fulfilled: response => {
            getArchive()
            return true
          },
        })
      })
      return request
    }
    const deleteAuditor = id => {
      const request = fromPromise(apiV1.delete(`${API_URL}/DeleteAuditor?id=${id}`))
      when(() => {
        request.case({
          fulfilled: response => {
            getAuditors()
            return true
          },
        })
      })
      return request
    }

    const editAuditor = async data => {
      const request = fromPromise(apiV1.put(`${API_URL}/EditAuditor`, data))
      return request
    }

    const addNewAuditor = async data => {
      const request = fromPromise(apiV1.post(`${API_URL}/CreateAuditor`, data))
      return request
    }

    const exportCSV = () => {
      const request = fromPromise(apiV1.get(`CsvExport/GetCsvFileOfAuditors`))
      return request
    }

    const updateDuplicateCertificate = data => {
      const request = fromPromise(apiV1.post(`${API_URL}/AddDuplicateCertificate`, data))
      return request
    }
    const archiveDuplicateCertificate = data => {
      const request = fromPromise(apiV1.put(`${API_URL}/InArchiveDuplicateCertificate`, data))
      return request
    }

    const updateResidentialAddress = data => {
      const request = fromPromise(apiV1.post(`${API_URL}/AddResidentialAddress`, data))
      return request
    }
    const archiveResidentialAddress = data => {
      const request = fromPromise(apiV1.put(`${API_URL}/InArchiveResidentialAddress`, data))
      return request
    }

    const updatePlaceOfWork = data => {
      const request = fromPromise(apiV1.post(`${API_URL}/AddOrEditPlaceOfWork`, data))
      return request
    }
    const archivePlaceOfWork = data => {
      const request = fromPromise(apiV1.put(`${API_URL}/InArchivePlaceOfWork`, data))
      return request
    }

    const updateReceipt = data => {
      const request = fromPromise(apiV1.post(`${API_URL}/AddOrEditReceipt`, data))
      return request
    }

    const archiveReceipt = data => {
      const request = fromPromise(apiV1.put(`${API_URL}/InArchiveReceipt`, data))
      return request
    }

    return {
      getAuditors,
      setAuditors,
      editAuditor,
      addNewAuditor,
      getArchive,
      recoverAuditor,
      deleteAuditor,
      exportCSV,
      updateDuplicateCertificate,
      archiveDuplicateCertificate,
      updateResidentialAddress,
      archiveResidentialAddress,
      updatePlaceOfWork,
      archivePlaceOfWork,
      updateReceipt,
      archiveReceipt,
    }
  })
  .views(self => ({
    get auditors() {
      return getSnapshot(self._auditors)
    },
    get deletedAuditors() {
      return getSnapshot(self._deletedAuditors)
    },
    get isPending() {
      return self._isPending
    },
  }))

export default AuditorsStore
